.animations {

  .sun {
    .svg {
      width: 100%;
      position: absolute;
      left: 0;
      top: -10px;
      z-index: -1;

      @media (max-width: 991px) {
        margin-top: 100px;
      }
    }
    
    .line {
      opacity: 0;
      animation: 4s forwards 0s ease-in Sun;
    }
  }

  .first-wave {
    
    .svg {
      width: 100%;
      position: absolute;
      left: 0;
      top: -10px;
      z-index: -1;

      @media (max-width: 991px) {
        margin-top: 100px;
      }
    }
    
    .line {
      opacity: 0;
      animation: 0.1s forwards 0.7s ease-in Wave;
    }
  }

  .second-wave {

    .svg {
      width: 100%;
      position: absolute;
      left: 0;
      top: -10px;
      z-index: -1;

      @media (max-width: 991px) {
        margin-top: 100px;
      }
    }
    
    .line {
      opacity: 0;
      animation: 0.1s forwards 0.6s ease-in Wave;
    }
  }

  .third-wave {

    .svg {
      width: 100%;
      position: absolute;
      left: 0;
      top: -10px;
      z-index: -1;

      @media (max-width: 991px) {
        margin-top: 100px;
      }
    }
    
    .line {
      opacity: 0;
      animation: 0.1s forwards 0.5s ease-in Wave;
    }
  }

  .fourth-wave {

    .svg {
      width: 100%;
      position: absolute;
      left: 0;
      top: -10px;
      z-index: -1;

      @media (max-width: 991px) {
        margin-top: 100px;
      }
    }
    
    .line {
      opacity: 0;
      animation: 0.1s forwards 0.4s ease-in Wave;
    }
  }


  .fifth-wave {

    .svg {
      width: 100%;
      position: absolute;
      left: 0;
      top: -10px;
      z-index: -1;

      @media (max-width: 991px) {
        margin-top: 100px;
      }
    }
    
    .line {
      opacity: 0;
      animation: 0.1s forwards 0.3s ease-in Wave;
    }
  }

  .sixth-wave {

    .svg {
      width: 100%;
      position: absolute;
      left: 0;
      top: -10px;
      z-index: -1;

      @media (max-width: 991px) {
        margin-top: 100px;
      }
    }
    
    .line {
      opacity: 0;
      animation: 0.1s forwards 0.2s ease-in Wave;
    }
  }

  .seventh-wave {

    .svg {
      width: 100%;
      position: absolute;
      left: 0;
      top: -10px;
      z-index: -1;

      @media (max-width: 991px) {
        margin-top: 100px;
      }
    }
    
    .line {
      opacity: 0;
      animation: 0.1s forwards 0.1s ease-in Wave;
    }
  }

  .eighth-wave {

    .svg {
      width: 100%;
      position: absolute;
      left: 0;
      top: -10px;
      z-index: -1;

      @media (max-width: 991px) {
        margin-top: 100px;
      }
    }
    
    .line {
      opacity: 0;
      animation: 0s forwards 0s ease-in Wave;
    }
  }

  .sand {

    .svg {
      width: 100%;
      position: absolute;
      left: 0;
      top: -10px;
      z-index: -1;

      @media (max-width: 991px) {
        margin-top: 100px;
      }
    }
    
    .line {
      opacity: 0;
      animation: 0s forwards 0s ease-in Sand;
    }
  }

  .palm-tree {
    @media (max-width: 991px) {
      display: none;
    }

    img {
      width: 30%;
      margin-left: -48%;
      margin-top: 5%;

      @media (max-width: 1458px) {
        margin-top: 3%;
      }
      @media (max-width: 1200px) {
        margin-top: 1%;
      }
      @media (min-width: 1800px) {
        margin-top: 10%;
      }
    }
  }

  .arrow {
    @media (max-width: 991px) {
      display: none;
    }
    
    p {
      color: black;
      padding: 3px;
      text-decoration: none;
    }

    img {
      width: 40px;
      animation: Arrow 2s;
      animation-direction: forwards;
      animation-iteration-count: 3;
    }
  }

}


@keyframes Wave {
  100% { opacity: 1; d: path("M 0 350 C -10 675 0 240 0 240 C 15 255 30 225 45 240 C 60 255 75 225 90 240 C 105 255 120 225 135 240 C 150 255 165 225 180 240 C 195 255 210 225 225 240 C 240 255 255 225 270 240 C 285 255 300 225 315 240 C 330 255 345 225 360 240 C 375 255 390 225 405 240 C 420 255 435 225 450 240 C 465 255 480 225 495 240 C 510 255 525 225 540 240 C 555 255 570 225 585 240 C 600 255 615 225 630 240 C 645 255 660 225 675 240 C 690 255 705 225 720 240 C 735 255 750 225 765 240 C 780 255 795 225 810 240 C 825 255 840 225 840 240 L 840 350 "); stroke: #111; }
}

@keyframes Sun {
  0% { opacity: 0; d: path("M 400 85 A 50 50 0 1 1 401 85 "); stroke: #111; }
  25% { opacity: .5; d: path("M 400 85 A 50 50 0 1 1 401 85 "); stroke: #111; }
  75% { opacity: .8; d: path("M 400 310 A 50 50 0 1 1 401 310 "); stroke: #111; }
  100% { opacity: .8; d: path("M 400 300 A 50 50 0 1 1 401 300 "); stroke: #111; }
}

@keyframes Sand {
  100% { opacity: 1; d: path("M 0 1500 L 0 300 C 390 240 450 330 840 300 L 840 1500 "); stroke: #111; }
}

@keyframes Arrow {
  from { transform: translate3d(0, 0, 0);}
  to { transform: translate3d(0, 25px, 0);}
}