.App {
  font-family: 'Raleway', sans-serif;
}

.nav-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  
  .logo {
    width: 150px;

    @media (max-width: 991px) {
      width: 100px;
    }

    @media (max-width: 700px) {
      width: 75px;
    }

    @media (max-width: 300px) {
      width: 50px;
    }
  }

  .contact-info {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    text-align: left;

    .email, .phone {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px;
      
      a {
        color: #444;
        font-weight: 800;
        font-size: 1.25rem;

        @media (max-width: 700px) {
          font-size: .75rem;
        }

        @media (max-width: 300px) {
          font-size: .5rem;
        }
      }
  
      img {
        width: 30px;
        padding-right: 2px;

        @media (max-width: 700px) {
          width: 20px;
        }

        @media (max-width: 300px) {
          width: 15px;
        }
      }
    }


  }
}

.Home {

  .description {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 20%;
    font-size: 1.5rem;
    font-weight: 500;
    border-radius: 20px;

    @media (max-width: 991px) {
      margin-top: 75%;
    }

    @media (max-width: 700px) {
      font-size: 1rem;
    }

    .agency-image {
      padding: 1%;

      .image1 {
        width: 600px;
        border-radius: 20px;
        box-shadow: 5px 5px 10px 0px #555;

        @media (max-width: 1458px) {
          width: 80%;
        }

        @media (max-width: 700px) {
          width: 90%;
        }
      }
    }

    .why-text {
      width: 800px;
      padding: 1%;

      @media (max-width: 1458px) {
        width: 80%;
      }

      @media (max-width: 700px) {
        width: 90%;
      }

      h1 {
        font-size: 5rem;
        font-weight: 900;
        border-bottom: 1px solid  #111;
        padding-bottom: 30px;
        text-align: left;

        @media (max-width: 700px) {
          font-size: 3rem;
        }
      }

      .agency {
        line-height: 1.8;
        text-align: left;
      }
    }


  }
}