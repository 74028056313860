@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

@import "./home.scss";
@import "./about.scss";
@import "./contact.scss";
@import "./footer.scss";
@import "./animations.scss";

body {
  font-size: 16px;
  text-align: center;
  color: #111;
  height: 100%;
  width: 100%;
  background: white;
}
