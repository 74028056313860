.footer {
  width: 100%;
  padding: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid black;
  font-size: 1rem;

  @media (max-width: 700px) {
    font-size: .65rem;
  }

  .website, .contact-info {
    padding: 1%;
    width: 100%;
  }
}